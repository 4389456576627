import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

function WarrantyGraphsQueryForm(props) {

    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
        const timeout = setTimeout(() => {
            setCleared(false);
        }, 1500);

        return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

     return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} display = "flex" flexDirection = "column">
            <Stack direction = "row" alignItems = {"center"}>
                <FormControl sx = {{ mr: '10px', width: '15%' }}>
                    <LocalizationProvider dateAdapter = {AdapterMoment}>
                        <DesktopDatePicker
                            label = " Start Date *"
                            name = 'startDate'
                            onChange = {(event) => props.handleDateChange(event, 'startDate')}
                            value = {props.startDate ? props.startDate : null}
                            renderInput = {(params) => <TextField {...params} />}
                            slotProps = {{
                                field: { clearable: true, onClear: () => setCleared(true) },
                              }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl sx = {{ mr: '10px', width: '15%' }}>
                    <LocalizationProvider dateAdapter = {AdapterMoment}>
                        <DesktopDatePicker
                            label = "End Date *"
                            name = 'endDate'
                            onChange = {(event) => props.handleDateChange(event, 'endDate')}
                            value = {props.endDate ? props.endDate :null}
                            renderInput = {(params) => <TextField {...params} />}
                            slotProps = {{
                                field: { clearable: true, onClear: () => setCleared(true) },
                              }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <Tooltip title = 'Do not enter date range to get all time data.' placement = 'right'>
                    <span>
                        <Button onClick = {()  => props.generate()} disabled = {props.isLoading} variant = "contained" color = "success" >Generate Graphs</Button>    
                    </span>
                </Tooltip>
            </Stack>
      </Box>
    );
  };

export default WarrantyGraphsQueryForm;