import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import {lighten } from '@mui/material/styles';

function HoldTable(props) {

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            minWidth: 100,
            flex: 1, 
            editable: false,
            hide: true
        }, 
        {
            field: 'vendor_name',
            headerName: 'Vendor',
            minWidth: 250,
            flex: 1, 
            editable: false
        }, 
        {
            field: 'internal_po_number',
            headerName: 'PO',
            renderCell: (params) => {
                return(
                    <Tooltip title  ={params.row.internal_po_number}>
                        <Box style = {{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{params.row.internal_po_number}</Box>
                    </Tooltip>
                )
            },
            minWidth: 200,
            flex: 1, 
            editable: false
        }, 
        {
            field: 'state',
            headerName: "State",
            minWidth: 50,
            flex: 1,
            editable: false
        },
        {
            field: 'order_date',
            headerName: 'Order Date',
            minWidth: 100,
            flex: 1, 
            editable: false,
            renderCell: (params) => {
                return(
                    <Tooltip title = {params.row.order_date}>
                        <Box style = {{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{params.row.order_date.substring(0, 10)}</Box>
                    </Tooltip>
                )
            }
        }, 
        {
            field: 'vendor_params',
            headerName: 'Requested On',
            minWidth: 100,
            flex: 1,
            editable: false,
            renderCell: (params) => {
                let vendor_params = JSON.parse(params.row.vendor_params || "{}");
                let req_date = vendor_params.requested_on || "";
                let date = `${req_date.substring(4, 6)}/${req_date.substring(6, 9)}/${req_date.substring(0, 4)}`
                return(
                    <Tooltip title = {date}>
                        <Box style = {{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{date}</Box>
                    </Tooltip>
                )
            },
            valueGetter: (params) => {
                let vendor_params = JSON.parse(params.row.vendor_params || "{}");
                return vendor_params.requested_on || "";
              },
            sortComparator: (v1, v2) => {
                if (!v1 || !v2) return 0;
                const date1 = new Date(v1.substring(0, 4), v1.substring(4, 6) - 1, v1.substring(6, 8));
                const date2 = new Date(v2.substring(0, 4), v2.substring(4, 6) - 1, v2.substring(6, 8));
                return date1 - date2;
            }
        },
        {
            field: 'pieces',
            headerName: 'Pieces',
            minWidth: 100,
            flex: 1, 
            editable: false
        }, 
        {
            field: 'total_price',
            headerName: 'Price',
            minWidth: 100,
            flex: 1, 
            editable: false
        }, 
        {
            field: "order_status",
            headerName: "Status",
            minWidth: 100,
            flex: 1,
            editable: false,
            renderCell: (params) => {
                let status = "Unknown";
                switch(params.row.order_status) {
                    case 198:
                        status = "Unacknowledged"
                        break;
                    case 197:
                        status = "Ready"
                        break;
                }
                return(
                    <Tooltip title = {status}>
                        <Box style = {{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{status}</Box>
                    </Tooltip>
                )
            }
        }
    ];



    return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'row'}}>
            <Box className = {props.isMobile ? "mobile-table-box" : "desktop-table-box"} style = {{ height: 1000, marginTop: '50px'}} sx = {{ 
                        '& .failed': {
                            bgcolor: (theme) => lighten(theme.palette.error.light, 0.5)
                        }
                        }}>
                <DataGrid
                className = {props.isMobile ? "mobile-table" : "desktop-table"} 
                rows = {props.orders}
                columns = {columns}
                rowsPerPageOption = {[25, 50, 100]}
                onSelectionModelChange = {(newSelectionModel) => {
                    props.handleSelect(newSelectionModel);
                }}
                selectionModel = {props.selected}
                sortModel = {[{field: 'vendor_params', sort: 'asc'}]}
                />
            </Box>            
        </Box>
    )
}

export default HoldTable