import Box from '@mui/material/Box';
import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import orderHelpers from '../../helpers/orders';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

function EditItemPopup(props) {

    const item = props.item;
    const [originalSku, setOriginalSku] = useState(item.original_sku || '');
    const [sku, setSku] = useState(item.internal_sku || '');
    const [quantity, setQuantity] = useState(item.quantity || '');
    const [lotNumber, setLotNumber] = useState(item.lot_number || '');
    const [warehouseNotes, setWarehouseNotes] = useState(item.warehouse_notes || '');
    const [notes, setNotes] = useState(item.notes || '');

    const filter = createFilterOptions();

    const handleChange = (event) => {
        let func = `set${event.target.name[0].toUpperCase() + event.target.name.substring(1)}`;
        eval(func)(event.target.value);
    }

    const requiredFields = [
        { field: 'original_sku', message: 'Original SKU is required.'},
        { field: 'internal_sku', message: 'SKU is required.'},
        { field: 'quantity', message: 'Quantity is required.'},
        { field: 'notes', message: 'Notes is required.'}
    ]
    
    const checkValidity = (orderInfo) => {
        let errorMessages = [];
        
        for (const { field, message } of requiredFields) {
            if (!orderInfo[field]) {
                errorMessages.push(message);
            }
        }
    
        if (errorMessages.length > 0) {
            const error_message = errorMessages.join('\n');
            props.toast.error(error_message);
            return false;
        }
        return true;
    };

    const updateWarrantyItem = async () => {
        props.setLoading(true);
        const itemInfo = {
            original_sku: originalSku,
            internal_sku: sku,
            quantity: quantity,
            lot_number: lotNumber,
            warehouse_notes: warehouseNotes,
            notes: notes,
            warranty_id: item.warranty_id
        }

        const databaseParams = {
            original_sku: originalSku !== "" ? originalSku : null,
            internal_sku: sku !== "" ? sku : null,
            quantity: quantity !== "" ? quantity : null,
            lot_number: lotNumber !== "" ? lotNumber : null,
            warehouse_notes: warehouseNotes !== "" ? warehouseNotes : null,
            notes: notes !== "" ? notes : null,
            warranty_id: item.warranty_id
        }
        if (!checkValidity(itemInfo)) {
            props.setLoading(false);
            return;
        }
        try {
            if(window.confirm(`Confirm changes for Warranty Order ${props.internal_po_number}`)) {
                const res = await orderHelpers.updateWarrantyItem(props.company, databaseParams, props.token);
                if(res) {
                    props.updateItemState(itemInfo);
                    props.toast.success('Saved Changes!');
                    props.handleClose();
                } else {
                    props.toast.error('Failed to Save Changes!');
                }
            }
        }
        catch(err) {
            console.log(err);
            props.toast.error('Failed to Save Changes!');
        }
        finally {
            props.setLoading(false);
        }
    }

    return(
        <Dialog open = {props.open} onClose={()=>props.handleClose()} fullWidth = {true} maxWidth = {'lg'}>
            <Box sx = {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
                <DialogTitle>
                        Edit Warranty Order {props.internal_po_number}
                </DialogTitle>
                <Button style = {{marginLeft:"auto", marginRight:"5%", width:"10%"}} onClick={()=>props.handleClose()} disabled={props.isLoading} endIcon={<CloseIcon/>} color="error">Cancel</Button>
            </Box>
            <DialogContent>
                <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'column'}}>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mt: -2, mb: '10px', ml: 2}}>
                        <label>Item</label>
                    </Box>
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', ml: 3, mb: '10px'}}>
                        <FormControl sx = {{ width: '18.9%', mr:"10px" }}>
                                <AutoComplete
                                    value = {originalSku}
                                    onChange = {(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setOriginalSku({value: newValue,});
                                        } else if (newValue && newValue.inputValue) {
                                            setOriginalSku({value: newValue.inputValue,});
                                        } else {
                                            setOriginalSku(newValue ? newValue.value : null);
                                        }
                                    }}
                                    filterOptions = {(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        const isExisting = options.some((option) => inputValue === option.value);
                                        if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            value: `Select "${inputValue}"`
                                        });
                                        }
                                        return filtered;
                                    }}
                                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id = "originalSku"  
                                    options = {(props.skuList)} 
                                    getOptionLabel = {(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.value;
                                    }}
                                    renderOption = {(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                        <li key={key} {...optionProps}>
                                            {option.value}
                                        </li>
                                        );
                                    }}
                                    renderInput = {(params) => (
                                        <TextField {...params} label="Original SKU" />
                                    )}
                                />
                            </FormControl>
                            <FormControl sx = {{ width: '18.9%', mr:"10px" }}>
                                <AutoComplete
                                    value = {sku}
                                    onChange = {(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setSku({value: newValue,});
                                        } else if (newValue && newValue.inputValue) {
                                            setSku({value: newValue.inputValue,});
                                        } else {
                                            setSku(newValue ? newValue.value : null);
                                        }
                                    }}
                                    filterOptions = {(options, params) => {
                                        const filtered = filter(options, params);
                                        const { inputValue } = params;
                                        const isExisting = options.some((option) => inputValue === option.value);
                                        if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            value: `Select "${inputValue}"`
                                        });
                                        }
                                        return filtered;
                                    }}
                                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id = "sku"  
                                    options = {(props.skuList)} 
                                    getOptionLabel = {(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.value;
                                    }}
                                    renderOption = {(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                        <li key={key} {...optionProps}>
                                            {option.value}
                                        </li>
                                        );
                                    }}
                                    renderInput = {(params) => (
                                        <TextField {...params} label = "SKU" />
                                    )}
                                />
                            </FormControl>
                            <TextField 
                                name = 'quantity'
                                label = 'Quantity'
                                onChange = {handleChange}
                                value = {quantity}
                                sx = {{width: '18.9%', mr: '10px'}}
                            />
                            <TextField 
                                name = 'lotNumber'
                                label = 'Lot Number'
                                onChange = {handleChange}
                                value = {lotNumber}
                                sx = {{width: '18.9%', mr: '10px'}}
                            />
                    </Box>  
                    <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', ml: 3, mb: '10px'}}>
                            <TextField 
                                name = 'warehouseNotes'
                                label = 'Warehouse Notes'
                                onChange = {handleChange}
                                value = {warehouseNotes}
                                sx = {{mr: '10px'}}
                                style = {{width: '38.5%'}}
                            />
                            <TextField 
                                name = 'notes'
                                label = 'Notes'
                                onChange = {handleChange}
                                value = {notes}
                                sx = {{mr: '10px'}}
                                style = {{width: '38.5%'}}
                            />
                    </Box>
                </Box>             
                </DialogContent>
                <DialogActions>
                    <Box display = "flex" sx = {{ mb: 5}} style = {{ width:"100%"}}>
                        <Button variant = "contained" style = {{marginLeft:"auto", marginRight:"5%", width:"10%"}} onClick = {()=>updateWarrantyItem()} disabled = {props.isLoading} color = "success" endIcon = {<SaveIcon />}>Save</Button>
                    </Box>
                </DialogActions>
        </Dialog>
    )
}

export default EditItemPopup;