import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ProcessingDetails from './ProcessingDetails';
import bulkHelpers from '../../../helpers/bulk'
import SplitTable from './SplitTable';
import ShipPanel from './ShipPanel';
import ReviewPanel from './ReviewPanel';
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';

function ProcessingPanel(props) {

    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [reviewReady, setReviewReady] = useState(false);
    const [ky_method, setKYMethod] = useState("");
    const [pa_method, setPAMethod] = useState("")
    const [ky_trucks, setKyTrucks] = useState([]);
    const [pa_trucks, setPaTrucks] = useState([]);

    useEffect(() => {
        let temp = [];
        for(let orders of props.orders) {
            for(let item of orders.items) {
                temp.push(item);
            }
        }
        setItems(temp);
    }, [props.order])


    useEffect(() => {
        if(items.every(obj => obj.decision && obj.decision !== "")) {
            if(items.some(obj => obj.decision === "Kentucky") && ky_method === "") setReviewReady(false);
            else if(items.some(obj => obj.decision === "Pennsylvania") && pa_method === "") setReviewReady(false);
            else setReviewReady(true)
        } 
        else {
            setReviewReady(false);
        }
    }, [items, ky_method, pa_method])

    const decisions = [
        "Kentucky",
        "Pennsylvania",
        "Split",
        "Partial",
        "Cancel",
        "Back Ordered",
        "Discontinued"
    ]
    // const [inv, setInv] = useState({});


    const overrideDecision = async(rows, decision) => {
        let temp = props.orders;
        let ids = rows.map(obj => obj.id);
        console.log(`overriding ${rows}, ${decision}`);
        let allCancel = true;
        let needsSplit = false;
        let out = [];
        for(let order of temp) {
            for(let i = 0; i < order.items.length; i++) {
                if(ids.includes(order.items[i].id)) {
                    order.items[i].decision = decision;
                }
                out.push(order.items[i])
            }
            if(!order.items.every(obj => obj.decision === "Cancel")) allCancel = false;
            if(!order.items.every(obj => obj.decision !== "Split" && obj.decision !== "Partial")) needsSplit = true;
        }
        await props.calculatePallets(temp)
        props.setOrders(temp);
        setItems(out);
        props.setAllCancel(allCancel);
        props.setNeedsSplit(needsSplit);
    }

    const setOrder = async(new_order) => {
        let temp = props.orders;
        for(let i = 0; i < temp.length; i++) {
            let order = temp[i]
            if(order.internal_po_number === order.internal_po_number) {
                temp[i] = new_order;
                break;
            }
        }
        props.setOrders(temp);
    }

    const handlePageChange = async(e, value) => {
        setPage(value);
    }




    return(
        <Box display="flex" flexDirection="column" style={{width:"100%"}}>
            <Tabs value={page} onChange={handlePageChange}>
                <Tab align="center" label={"Decisions"} />
                <Tab align="center" label={"Split"} disabled={props.all_cancel || !props.needs_split} />
                <Tab align="center" label={"Ship Method"} disabled={props.all_cancel} />
                <Tab align="center" label={"Review"} disabled={!reviewReady} />
            </Tabs>
            {page === 0 ?
                <Box style={{width:"100%"}}>
                    <ProcessingDetails {...props} decisions={decisions} overrideDecision={overrideDecision} items={items} />
                </Box>
            : null}
            {page === 1 && !props.all_cancel ?
                <Box style={{width:"100%"}}>
                    {props.orders.map((obj, index) => {
                        return(
                            <SplitTable calculatePallets={props.calculatePallets} {...props} key={index} order={obj} setOrder={setOrder}/>
                        )
                    })}
                </Box>  
            : null}
            {page === 2 && !props.all_cancel ?
                <Box style={{width:"100%"}}>
                    <ShipPanel  ky_trucks={ky_trucks} setKyTrucks={setKyTrucks} pa_trucks={pa_trucks} setPaTrucks={setPaTrucks} ky_items={items.filter(obj => obj.decision === "Kentucky")} ky={items.some(obj=>obj.decision==="Kentucky")} pa={items.some(obj=>obj.decision==="Pennsylvania")} pa_items={items.filter(obj=>obj.decision==="Pennsylvania")} setKYMethod={setKYMethod} setPAMethod={setPAMethod} ky_method={ky_method} pa_method={pa_method} saveShipMethods={setItems} {...props} />
                </Box>
            : null}
            {page === 3 && reviewReady ?
                <Box style={{width:"100%"}}>
                    <ReviewPanel  ky_method={ky_method} pa_method={pa_method} {...props} />
                </Box>
            : null}


                {/* {
                    !props.all_cancel ?
                    <Button size="large" style={{height:"fit-content", margin:"auto", marginLeft:"30px"}} variant="contained" color="success">Continue <ArrowForwardIcon /> </Button>
                    :
                    null
                } */}
        </Box>
    )
}

export default ProcessingPanel;