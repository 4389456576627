import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const columns = [
    {
        field: 'order_date',
        headerName: 'Order Date',
        flex: 1,
        minWidth: 100,
        valueGetter: (orderInfo) => {
            if(!orderInfo.row.order_date) return "";
            return orderInfo.row.order_date.substring(0, 10)
        }, 
        editable: false
    },
    {
        field: 'internal_po_number',
        headerName: 'PO Number',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'external_po_number',
        headerName: 'Original PO Number',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'customer_name',
        headerName: 'Customer Name',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'original_sku',
        headerName: 'Original Item',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'internal_sku',
        headerName: 'Item',
        flex: 1,
        minWidth: 75,
        editable: false
    }, 
    {
        field: 'quantity',
        headerName: 'Quantity',
        flex: 1,
        minWidth: 75,
        editable: false
    }
]

function WarrantyTable(props) {

    return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"}>
            <Box className = {props.isMobile ? "mobile-table-box" : "desktop-table-box"}>
                <Stack direction = 'row' sx = {{width: '81%'}} spacing = {2}> 
                    <DataGrid
                        className = {props.isMobile ? "mobile-table" : "desktop-table"}
                        sx = {{ 
                            height: "1000px",
                            '& .red-row': {
                                backgroundColor: '#FAA0A0', 
                                '&:hover': {
                                    backgroundColor: '#cc3333',  
                                },
                            },
                        }}
                        rows = {props.orderDetails}
                        columns = {columns}
                        rowsPerPageOptions = {[25, 50, 100]}
                        checkboxSelection = {false}
                        onRowClick = {props.setOpenDetails}
                        getRowClassName = {(items) =>
                            items.row.order_status === 199 ? 'red-row' : ''
                        }
                    />
                    <Tooltip title = {"Export orders."}  sx = {{width: '1000px'}} placement = "right-start" >
                        <span>
                            <IconButton 
                                aria-label = "exportOrders" 
                                onClick = {props.exportOrders} 
                                sx = {{mt: '5px', color: '#96605a'}}>
                                <FileDownloadIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            </Box>
        </Box>
    )

}

export default WarrantyTable;