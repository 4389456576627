import axios from 'axios';
import 'dotenv/config';
import getJWT from './token';
import zlib from 'zlib';

let api = `https://api.${process.env.REACT_APP_BASE_URL || "mattk.commerce-ims.com"}`;

const orderHelpers = {

    getOrders: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            let orders = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
            orders.forEach((order, index) => {
                order['id'] = index;
                if(order.order_date) {
                    order.order_date = order.order_date.replace(/T/g,' ')
                }
            })
            return orders;
        } catch (err) {
            return {
                success: false, 
                error: err
            };
        }
    },

    getVendorPriority: async function (company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/vendors/priority`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            return res.data;
        } catch (err) {
            throw err;
        }
    },

    updateVendorPriority: async function (company_id, token, body){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/vendors/priority`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            return res.data;
        } catch (err) {
            throw err;
        }
    },

    splitPullsheet: async function(company_id, body, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/pullsheets/split`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            return;
        } catch (err) {
            throw err;
        }
    },

    downloadInvoices: async function(token, company_id) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/invoices`;
        try {
            let res = await axios.post(url, '{}', { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            return res.data;
        } catch (err) {
            throw err;
        }
    },

    getWarehouseReport: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/warehouse_report`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            // let orders = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
            // orders.forEach((order, index) => {
            //     order['id'] = index;
            //     if(order.order_date) {
            //         order.order_date = order.order_date.replace(/T/g,' ')
            //     }
            // })
            return res.data;
        } catch (err) {
            return {
                success: false, 
                error: err
            };
        }
    },

    getBulkOrders: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/bulk/orders`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            let orders = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
            orders.forEach((order, index) => {
                order['id'] = index;
                if(order.order_date) {
                    order.order_date = order.order_date.replace(/T/g,' ')
                }
            })
            return orders;
        } catch (err) {
            console.log(err);
            return null
        }
    },

    resendAcumaticaPullsheet: async function(body, token, company_id) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/pullsheets/resendAcumatica`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
        } catch (err) {
            console.log(err);
            return null
        }
    },

    saveBulkOrderDecisions: async function(company_id, token, body, ignore_cancel) {
        const userToken = await getJWT(token);
        const url = `${api}/bulk/orders/decisions`;
        try {
            const zlib = require("zlib");
            body.ignore_cancel= ignore_cancel;
            let out = JSON.stringify({data: zlib.deflateSync(JSON.stringify(body)).toString('base64')})
            let res = await axios.put(url, out, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
        } catch (err) {
            console.log(err);
            return null
        }
    },

    getUploads: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/uploads`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params });
            res.data.forEach(upload => {
                upload['id'] = upload.upload_id
            })
            return res.data;
        } catch (err) {
            // console.log(err);
            return err;
        }
    },

    getTracking: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/uploads/tracking`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params});
            return res.data;
        }
        catch(err) {
            console.log(err);
            return [];
        }
    },
    sendOrderToProduction: async function(company_id, body, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/sendToProduction`
        try {
            await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }});
        }
        catch(err) {
            console.log(err);
            return [];
        }
    },

    removeFromPullsheet: async function (body, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/pullsheets/removePackage`;
        await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }});
        return
    },

    getPullSheets: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/pullsheets`;
        let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params });
        res.data?.forEach((pullSheet, index) => {
            pullSheet['id'] = pullSheet.pullsheet_id;
            pullSheet['date'] = pullSheet.date.split('T')[0];
        })
        return {
            success: true, 
            pullsheets: res.data
        };

    },

    acknowledgeOrder: async function(company_id, body, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/ack`;
        await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }});
        return;
    },

    getHoldOrders: async function(company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/on_hold`;
        let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
        res.data?.forEach((row, index) => {
            row['id'] = row.order_id;
        })
        return res.data;
    },

    createPullSheet: async function(company_id, body, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/pullsheets`;
        try {
            let res = await axios.post(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },

    redoTracking: async function(company_id, body, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/redo_tracking`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },

    fallbackAmazon: async function(company_id, body, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/fallback_amazon`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },

    redoSingleTracking: async function(company_id, body, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/redo_single_tracking`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },

    updateCustomerInfo: async function(company_id, body, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/update_customer`;
        try {
            let res = await axios.put(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },

    deleteUpload: async function(company_id, body, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/uploads`;
        try {
            let res = await axios.delete(url, {data: body, headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }});
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            console.log(err);
            return false;        }
    },

    cancelItems: async function(data, company_id, token) {
        const userToken = await getJWT(token);
        let obj = {headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }}
        console.log(JSON.stringify(obj))
        let res = await axios.put(`${api}/dropship/orders/remove_from_orders`, data, obj);
        return res;
    },

    formatOrders: function(orders){
        const orderArray = [];
        orders.forEach(order =>{
            orderArray.push(order.data)
        })
        return orderArray;
    },

    getUSPSOrders: async function(warehouse, token, company_id) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/usps_tracking?warehouse=${warehouse}`;
        try {
            let res = await axios.get(url, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 200) {
                return res.data;
            }
            else {
                return [];
            }
        }
        catch(err) {
            console.log(err)
        }
    },

    resendAmazon: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/vendor/label_requests`;
        try {
            let res = await axios.post(url, body, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    getScanForms: async function(token, company_id) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/usps/scansheets`;
        try {
            let res = await axios.get(url, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            return res.data;
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    
    getScanForm: async function(key, token, company_id) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/usps/scansheet?key=${key}`;
        let res = await axios.get(url, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
        return res.data;
    },


    generateScanForms: async function(token, company_id) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/usps/scansheet`;
        let res = await axios.post(url, '{}', {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
        return res.data;

    },
    
    repullAmazon: async function(body, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/vendor/get_label_requests`;
        try {
            let res = await axios.post(url, body, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },
    getUnprocessedOrders: async function(company_id, warehouse, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/unprocessed?warehouse=${warehouse}`;
        try {
            let res = await axios.get(url, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 200) {
                return res.data;
            }
            else {
                return [];
            }
        }
        catch(err) {
            console.log(err)
        }
    },

    getFailedTracking: async function(company_id, warehouse, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/failed_tracking?warehouse=${warehouse}`;
        try {
            let res = await axios.get(url, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 200) {
                let out = res.data.map((obj, index) => {
                    obj.id = index;
                    return obj;
                })
                return out;
            }
            else {
                return [];
            }
        }
        catch(err) {
            console.log(err);
            return err;
        }
    },
    setTracking: async function(company_id, order_id, tracking_number, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/update_tracking`;
        try {
            let res = await axios.put(url, {order_id: order_id, tracking_number: tracking_number}, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    setWarrantyOrder: async function(company_id, params, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/warranty`;
        try {
            let res = await axios.post(url, params, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            console.log(res)
            if(res.status === 204) {
                return {
                    success: true
                };
            } 
            else {
                return {
                    success: false,
                    message: "Failed to Insert Warranty Order!"
                };
            }
        }
        catch(err) {
            if (err.response.status === 400 && err.response.data.errorMessage) {
                return{
                    success: false,
                    message: "Duplicate PO Number!"
                }
            }
            console.log(err.response);
            return false;
        }
    },

    updateWarrantyItem: async function(company_id, params, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/warranty/update_item`;
        try {
            let res = await axios.put(url, params, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    updateWarrantyOrderInfo: async function(company_id, params, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/warranty/update_order_info`;
        try {
            let res = await axios.put(url, params, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    cancelWarrantyOrder: async function(company_id, internal_po_number, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/warranty/cancel`;
        try {
            let res = await axios.put(url, JSON.stringify({internal_po_number: internal_po_number}), {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    getWarrantyOrderDetails: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/warranty/orders`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            let orders = JSON.parse(zlib.inflateSync(Buffer.from(res.data, 'base64')));
            orders.forEach((order) => {
                order['id'] = order.warranty_id;
                if(order.order_date) {
                    order.order_date = order.order_date.replace(/T/g,' ')
                }
            })
            return orders;
        } catch (err) {
            return {
                success: false, 
                error: err
            };
        }
    },

    transferItems: async function(items, po, company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/transfer`;
        try {
            let res = await axios.put(url, {order_ids: items, internal_po_number: po}, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return;
            }
            else {
                return [];
            }
        }
        catch(err) {
            console.log(err)
        }
    },

    vendorRequest: async function(vendor_name, request_type, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/vendor`;
        try {
            let res = await axios.post(url, {vendor_name: vendor_name, request_type: request_type}, {headers: {company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            }
            else {
                return false;
            }
        }
        catch(err) {
            console.log(err);
            return false;
        }
    },

    uploadOrders: async function(company_id, body, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/uploads`;
        try {
            let res = await axios.post(url, body, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } });
            if(res.status === 204) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return err;
        }
    },

    getLabelsDownloadLink: async function(company_id, params, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/pullsheets/labels`
        try {
            let res =await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            return res.data;
        } catch (err) {
            throw err;
        }
    },

    getPackageLabelDownloadLinks: async function(company_id, params, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/label`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }, params })
            return res.data;   
        } catch (err) {
            return err;
        }
    },

    resendAcumatica: async function(internal_po_number, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/acumatica/resend`;
        try {
            let res = await axios.put(url, JSON.stringify({internal_po_number: internal_po_number}), { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err
            }
        }
    },

    reshipPackage: async function(company_id, payload, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/reship`;
        try {
            let res = await axios.put(url, JSON.stringify(payload), { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err
            }
        }
    },

    cancelOrder: async function(internal_po_number, vendor, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/cancel`;
        try {
            let res = await axios.put(url, JSON.stringify({internal_po_number: internal_po_number, vendor: vendor}), { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            console.log(err)
            return {
                success: false,             
                error: err
            }
        }
    },

    holdOrder: async function(internal_po_number, vendor, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/hold`;
        try {
            let res = await axios.put(url, JSON.stringify({internal_po_number: internal_po_number, vendor: vendor}), { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err
            }
        }
    },

    getMasterPackslips: async function(company_id, payload, token) {
        const userToken = await getJWT(token);
        let  url = `${api}/dropship/orders/master_packslips`;
        if(payload.upload_id) url += `?upload_id=${payload.upload_id}`
        else url += `?pullsheet_id=${payload.pullsheet_id}`
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return res.data
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err
            }
        }
    },

    regenPackslips: async function(company_id, req, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/packslips/regen`;
        try {
            let res = await axios.put(url, JSON.stringify(req), { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true
            }
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err
            }
        }
    },

    getUngroupedOrders: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/ungrouped`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` } })
            return {
                success: true, 
                data: res.data
            }
        } catch (err) {
            console.log(err)
            return {
                success: false,
                error: err
            }
        }
    }, 

    getDropshipItems: async function(company_id, token){
        const userToken = await getJWT(token);
        const url = `${api}/dropship/items`;
        try {
            let res = await axios.get(url, { headers: { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` }})
            let data = res.data.map(obj => obj.sku);
            return {
                success: true,
                items: data
            } ;
        } catch (err) {
            return {
                success: false, 
                error: err
            };
        }
    },

    updateAcuOrderNumByPO: async function(internal_po_number, acu_order_num, company_id, token) {
        const userToken = await getJWT(token);
        const url = `${api}/dropship/orders/update_acu_order_num`;
        const body = {internal_po_number, acu_order_num};
        const headers = { company_id:company_id[0].toUpperCase() + company_id.substring(1), Authorization: `Bearer ${userToken}` };
        try {
            let res = await axios.put(url, JSON.stringify(body), { headers: headers })
            return {
                success: true
            }
        } catch (err) {
            console.log(err);
            return {
                success: false
            }
        }
    }
    
}

export default orderHelpers;