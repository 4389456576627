import React, { useEffect } from 'react';
import { BarChart, LineChart, XAxis, YAxis, Tooltip, Bar, Line, CartesianGrid, Legend, Cell} from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function WarrantyGraphsTable(props) {

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#d0ed57', '#a4de6c'];

    const getTicks = (data) => {
        if (!data || data.length === 0) return [];
    
        const ticks = [];
        ticks.push(data[0].x); 
        if (data.length > 2) {
            ticks.push(data[Math.floor(data.length / 2)].x);
        }
        ticks.push(data[data.length - 1].x);
        return ticks;
      
    };

    return (
        <Box className = {props.isMobile ? "mobile-table-box" : "desktop-table-box"} sx = {{mb: 125}}>
            <h2>Total Number of Issues: {props.totalIssues.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0} </h2>
            <Box display = "flex" justifyContent = "space-between">
            <Stack direction = "column" spacing = {10}>
                {props.totalIssuesByDateData?.length > 0 ? 
                    <Box display = "flex" justifyContent = "space-between" textAlign = "center" > 
                        <Stack spacing = {2}>
                            <Typography sx = {{fontWeight: 'bold'}}>Number of Issues per Day</Typography>
                            <LineChart width = {1250} height = {275} data = {props.totalIssuesByDateData}>
                                <CartesianGrid strokeDasharray = "3 3" />
                                <XAxis 
                                    label = {{ value: 'Date', position: 'insideBottom', offset: -5 }} 
                                    dataKey = "x" 
                                    height = {50}
                                    tick = {{ textAnchor: 'middle' }} 
                                    interval = {props.totalIssuesByDateData?.length < 15 ? 0 : undefined} 
                                    ticks = {props.totalIssuesByDateData?.length >= 15 ? getTicks(props.totalIssuesByDateData) : undefined}
                                />
                                <YAxis label = {{ value: 'Issue Count',  angle: -90, position: 'insideLeft'}} />
                                <Tooltip />
                                <Legend content = {() => null}/>
                                <Line type = "monotone" dataKey = "y" dot = {{strokeWidth: 3}} stroke = "#8dd1e1" strokeWidth = {3}/>
                            </LineChart>
                        </Stack>
                    </Box> : null
                }
                {props.totalIssuesByReasonData?.length > 0 ?
                    <Box display = "flex" justifyContent = "space-between" textAlign = "center" > 
                        <Stack spacing = {2}>
                        <Typography sx = {{fontWeight: 'bold'}}>Number of Issues per Reason</Typography>
                            <BarChart width = {1250} height = {400} data = {props.totalIssuesByReasonData}>
                                <CartesianGrid strokeDasharray = "3 3" />
                                <XAxis 
                                    label = {{ value: 'Reason', position: 'insideBottom', offset: -5 }} 
                                    dataKey = "x" 
                                    tick = {{ angle: -65, textAnchor: 'end', fontSize: 12 }} 
                                    interval = {0} 
                                    height = {175}
                                />
                                <YAxis label = {{ value: 'Issue Count',  angle: -90, position: 'insideLeft'}} />
                                <Tooltip />
                                <Legend content = {() => null}/>
                                <Bar dataKey = "y">
                                    {props.totalIssuesByReasonData.map((entry, index) => (
                                        <Cell key = {`cell-${index}`} fill = {colors[index % colors.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </Stack>
                    </Box> : null
                }
                {props.totalIssuesByVendorData?.length > 0 ?
                    <Box display = "flex" justifyContent = "space-between" textAlign = "center" > 
                        <Stack spacing = {2}>
                        <Typography sx = {{fontWeight: 'bold'}}>Number of Issues per Vendor</Typography>
                            <BarChart width = {1250} height = {375} data = {props.totalIssuesByVendorData}>
                                <CartesianGrid strokeDasharray = "3 3" />
                                <XAxis 
                                    label = {{ value: 'Vendor', position: 'insideBottom', offset: -5 }} 
                                    dataKey = "x" 
                                    tick = {{ angle: -55, textAnchor: 'end', fontSize: 12 }} 
                                    interval = {0} 
                                    height = {150}
                                />
                                <YAxis label = {{ value: 'Issue Count',  angle: -90, position: 'insideLeft'}} />
                                <Tooltip />
                                <Legend content = {() => null}/>
                                <Bar dataKey = "y">
                                    {props.totalIssuesByVendorData.map((entry, index) => (
                                        <Cell key = {`cell-${index}`} fill = {colors[index % colors.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </Stack>
                    </Box> : null
                }
                 {props.totalIssuesBySkuData?.length > 0 ?
                    <Box display = "flex" justifyContent = "space-between" textAlign = "center" > 
                        <Stack spacing = {2}>
                        <Typography sx = {{fontWeight: 'bold'}}>Top 30 SKUs with the Most Issues</Typography>
                            <BarChart width = {1250} height = {375} data = {props.totalIssuesBySkuData}>
                                <CartesianGrid strokeDasharray = "3 3" />
                                <XAxis 
                                    label = {{ value: 'SKU', position: 'insideBottom', offset: -5 }} 
                                    dataKey = "x" 
                                    tick = {{ angle: -65, textAnchor: 'end', fontSize: 12 }} 
                                    interval = {0} 
                                    height = {125}
                                />
                                <YAxis label = {{ value: 'Issue Count',  angle: -90, position: 'insideLeft'}} />
                                <Tooltip />
                                <Legend content = {() => null}/>
                                <Bar dataKey = "y">
                                    {props.totalIssuesBySkuData.map((entry, index) => (
                                        <Cell key = {`cell-${index}`} fill = {colors[index % colors.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </Stack>
                    </Box> : null
                }
            </Stack>
           </Box>
        </Box>
    )
}

export default WarrantyGraphsTable;