import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import States from './States';
import AddIcon from '@mui/icons-material/Add';
import WarrantyOrderOptions from './WarrantyOrderOptions';
import moment from 'moment';

function WarrantyInsertForm(props) {

    useEffect(() => {
        const allVendors = props.allVendors?.map(value => ({ value: value, name: value}));
        const allVendorsFirstLetter = allVendors?.map((option) => {
            const firstLetter = option.value[0].toUpperCase();
            return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
            };
        });
        const allVendorsGrouped = allVendorsFirstLetter.sort((a, b) => {
            const letterComparison = a.firstLetter.localeCompare(b.firstLetter);
            if (letterComparison !== 0) return letterComparison;
            return a.name.localeCompare(b.name);
        });
        setVendorDropdownOptions(allVendorsGrouped);
    }, [props.allVendors]);

    const filter = createFilterOptions();

    const dropdownStateOptions = States.map(value => ({ value: value, name: value}));

    // dropship
    const dropshipVendors = props.dropshipVendors?.map(value => ({ value: value, name: value}));
    const dropshipVendorsFirstLetter = dropshipVendors?.map((option) => {
        const firstLetter = option.value[0].toUpperCase();
        return {
           firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
           ...option,
        };
     });
     const dropshipVendorsGrouped = dropshipVendorsFirstLetter.sort((a, b) => {
        const letterComparison = a.firstLetter.localeCompare(b.firstLetter);
        if (letterComparison !== 0) return letterComparison;
        return a.name.localeCompare(b.name);
    });

    // bulk
    const bulkVendors = props.bulkVendors?.map(value => ({ value: value, name: value}));
    const bulkVendorsFirstLetter = bulkVendors?.map((option) => {
        const firstLetter = option.value[0].toUpperCase();
        return {
           firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
           ...option,
        };
     });
     const bulkVendorsGrouped = bulkVendorsFirstLetter.sort((a, b) => {
        const letterComparison = a.firstLetter.localeCompare(b.firstLetter);
        if (letterComparison !== 0) return letterComparison;
        return a.name.localeCompare(b.name);
    });
     // all vendors
    const allVendors = props.allVendors?.map(value => ({ value: value, name: value}));
    const allVendorsFirstLetter = allVendors?.map((option) => {
        const firstLetter = option.value[0].toUpperCase();
        return {
           firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
           ...option,
        };
     });

    const allVendorsGrouped = allVendorsFirstLetter.sort((a, b) => {
        const letterComparison = a.firstLetter.localeCompare(b.firstLetter);
        if (letterComparison !== 0) return letterComparison;
        return a.name.localeCompare(b.name);
    });

    const [vendorDropdownOptions, setVendorDropdownOptions] = useState(allVendors);

    const determineVendorOptions = (orderType) => {
        if (!orderType) {
            setVendorDropdownOptions(allVendorsGrouped);
        } else if (orderType.value === 'Dropship') {
            setVendorDropdownOptions(dropshipVendorsGrouped)
        } else if (orderType.value === 'Bulk') {
            setVendorDropdownOptions(bulkVendorsGrouped);
        }
    }

    return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'column'}}>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <label>Order</label>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <FormControl sx = {{ mr: '0.6%', width: '15%' }}>
                <LocalizationProvider dateAdapter = {AdapterMoment}>
                    <DatePicker
                        label = "Date *"
                        name = 'Date'
                        onChange = {(newValue) => props.handleDateChange(newValue, 'date')}
                        value = {props.date ? moment(props.date) : moment()}
                        renderInput = {(params) => <TextField {...params} />}
                        clearable = {true}
                    />
                </LocalizationProvider>
            </FormControl>
            <TextField 
                name = 'poNumber'
                label = 'PO Number'
                onChange = {props.handleChange}
                value = {props.poNumber}
                sx = {{width: '15%', mr: '0.6%'}}
                required
            />
            <TextField 
                name = 'originalPoNumber'
                label = 'Original PO Number'
                onChange = {props.handleChange}
                value = {props.originalPoNumber}
                sx = {{width: '15%', mr: '0.6%'}}
            />
            <FormControl sx = {{ width: '15%' , mr: '0.6%'}}>
                <AutoComplete
                    value = {props.type}
                    onChange = {(event, newValue) => {
                        determineVendorOptions(newValue);
                        if (typeof newValue === 'string') {
                            props.setType({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setType({value: newValue.inputValue,});
                        } else {
                            props.setType(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "type"
                    options = {WarrantyOrderOptions.type}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Type" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '15%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.vendor}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setVendor({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setVendor({value: newValue.inputValue,});
                        } else {
                            props.setVendor(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            value: `Select "${inputValue}"`
                        });
                        }
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "vendor"  
                    options = {vendorDropdownOptions} 
                    groupBy = {(option) => option.firstLetter}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Vendor" />
                    )}
                />
            </FormControl>
            </Box>
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.warehouse}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setWarehouse({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setWarehouse({value: newValue.inputValue,});
                        } else {
                            props.setWarehouse(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "warehouse"
                    options = {WarrantyOrderOptions.warehouses}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Original Warehouse" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.action}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setAction({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setAction({value: newValue.inputValue,});
                        } else {
                            props.setAction(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "action"
                    options = {WarrantyOrderOptions.action}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Action *" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.reason}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setReason({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setReason({value: newValue.inputValue,});
                        } else {
                            props.setReason(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            value: `Select "${inputValue}"`
                        });
                        }
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "reason"
                    options = {WarrantyOrderOptions.reasons}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Reason *" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.fastShipping}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setFastShipping({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setFastShipping({value: newValue.inputValue,});
                        } else {
                            props.setFastShipping(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "fastShipping"
                    options = {WarrantyOrderOptions.fast_shipping}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Fast Shipping" />
                    )}
                />
            </FormControl>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <label>Address</label>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                name = 'name'
                label = 'Name'
                onChange = {props.handleChange}
                value = {props.name}
                sx = {{width: '12.4%', mr: '0.6%'}}
                required
            />
            <TextField 
                    name = 'street'
                    label = 'Street'
                    onChange = {props.handleChange}
                    value = {props.street}
                    sx = {{width: '12.4%', mr: '0.6%'}}
                    required
            />
            <TextField 
                    name = 'street2'
                    label = 'Street 2'
                    onChange = {props.handleChange}
                    value = {props.street2}
                    sx = {{width: '12.4%', mr: '0.6%'}}
            />
            <TextField 
                    name = 'city'
                    label = 'City'
                    onChange = {props.handleChange}
                    value = {props.city}
                    sx = {{width: '12.4%', mr: '0.6%'}}
                    required
            />
            <FormControl sx = {{ width: '12.4%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.state}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setState({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setState({value: newValue.inputValue,});
                        } else {
                            props.setState(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "state"
                    options = {dropdownStateOptions}
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "State *" />
                    )}
                />
            </FormControl>
            <TextField 
                    name = 'zipCode'
                    label = 'Zip Code'
                    onChange = {props.handleChange}
                    value = {props.zipCode}
                    sx = {{mr: '0.6%'}}
                    style = {{width: '12.4%'}}
                    required
            />
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <label>Item(s)</label>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
        <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.originalSku}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setOriginalSku({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setOriginalSku({value: newValue.inputValue,});
                        } else {
                            props.setOriginalSku(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            value: `Select "${inputValue}"`
                        });
                        }
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "originalSku"  
                    options = {(props.skuList)} 
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                            return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "Original SKU *" />
                    )}
                />
            </FormControl>
            <FormControl sx = {{ width: '18.9%', mr: '0.6%' }}>
                <AutoComplete
                    value = {props.sku}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setSku({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setSku({value: newValue.inputValue,});
                        } else {
                            props.setSku(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            value: `Select "${inputValue}"`
                        });
                        }
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "sku"  
                    options = {(props.skuList)} 
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key = {key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label = "SKU *" />
                    )}
                />
            </FormControl>
            <TextField 
                name = 'quantity'
                label = 'Quantity'
                onChange = {props.handleChange}
                value = {props.quantity}
                sx = {{width: '18.9%', mr: '0.6%'}}
                required
            />
            <TextField 
                name = 'lotNumber'
                label = 'Lot Number'
                onChange = {props.handleChange}
                value = {props.lotNumber}
                sx = {{width: '18.9%', mr: '0.6%'}}
            />
        </Box>  
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                name = 'warehouseNotes'
                label = 'Warehouse Notes'
                onChange = {props.handleChange}
                value = {props.warehouseNotes}
                sx = {{mr: '0.6%'}}
                style = {{width: '38.4%'}}
            />
            <TextField 
                name = 'notes'
                label = 'Notes'
                onChange = {props.handleChange}
                value = {props.notes}
                sx = {{mr: '0.6%'}}
                style = {{width: '38.4%'}}
                required
            />
        </Box> 
        <Button variant = "contained" onClick = {props.setMultipleItemWarrantyOrder} sx = {{mt: '10px', width: 235}} endIcon = {<AddIcon />}> Add Item </Button>
        <Button variant = 'contained' onClick = {props.setWarrantyOrder} color = "success" sx = {{mt: '10px', width: 235}}>Insert</Button>
    </Box>
    )
}

export default WarrantyInsertForm;