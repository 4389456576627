import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import orderHelpers from '../../helpers/orders';
import WarrantyGraphsTable from './WarrantyGraphsTable';
import WarrantyGraphsQueryForm from './WarrantyGraphsQueryForm';

function WarrantyGraphs(props) {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [limit, setLimit] = useState('');
    const [warrantyOrders, setWarrantyOrders] = useState([]);
    const [totalIssuesByDateData, setTotalIssuesByDateData] = useState([]);
    const [totalIssuesByReasonData, setTotalIssuesByReasonData] = useState([]);
    const [totalIssuesBySkuData, setTotalIssuesBySkuData] = useState([]);
    const [totalIssuesByVendorData, setTotalIssuesByVendorData] = useState([]);
    const [totalIssues, setTotalIssues] = useState('');

    useEffect(() => {
        issuesPerDayData(warrantyOrders);
        issuesByReasonData(warrantyOrders);
        issuesBySkuData(warrantyOrders);
        issuesByVendorData(warrantyOrders);
    }, [warrantyOrders]); 

    const formatDate = (date) => {
        const formatted_date = new Date(date);
        const month = formatted_date.getMonth() + 1; 
        const day = formatted_date.getDate() + 1;
        const year = formatted_date.getFullYear().toString().slice(-2);
        return `${month}-${day}-${year}`;
    };

    const issuesPerDayData = async (orders) => {
        const data = {};
        for (let order of orders) {
            const date = formatDate(order.order_date);
            if (!data[date]) {
                data[date] = 0;
            }
            data[date] += 1;
        }
        const data_array = Object.keys(data).map(date => ({
            x: date,
            y: data[date],
            originalDate: new Date(date)
        }));
    
        data_array.sort((a, b) => a.originalDate - b.originalDate);

        const final_data_array = data_array.map(({ x, y }) => ({ x, y }));
    
        setTotalIssuesByDateData(final_data_array);
    }

    const issuesByReasonData = async (orders) => {
        const data = {};

        for (let order of orders) {
            let reason = '';
            if (!order.reason) {
                reason = 'Other';
            } else {
                reason = order.reason;
            }
            if (!data[reason]) {
                data[reason] = 0;
            }
            data[reason] += 1
        }
        const data_array = Object.keys(data).map(reason => ({
            x: reason,
            y: data[reason]
        })).sort((a, b) => b.y - a.y);
        
        setTotalIssuesByReasonData(data_array);
    }

    const issuesByVendorData = async (orders) => {
        const data = {};

        for (let order of orders) {
            let vendor = '';
            if (!order.vendor) {
                vendor = 'Other';
            } else {
                vendor = order.vendor;
            }
            if (!data[vendor]) {
                data[vendor] = 0;
            }
            data[vendor] += 1
        }
        const data_array = Object.keys(data).map(vendor => ({
            x: vendor,
            y: data[vendor]
        })).sort((a, b) => b.y - a.y);
        
        setTotalIssuesByVendorData(data_array);
    }
    
    const issuesBySkuData = async (orders) => {
        const data = {};

        for (let order of orders) {
            let sku = '';
            if (!order.original_sku) {
                continue;
            } else {
                sku = order.original_sku;
            }
            if (!data[sku]) {
                data[sku] = 0;
            }
            data[sku] += 1
        }
        const data_array = Object.keys(data).map(sku => ({
            x: sku,
            y: data[sku]
        })).sort((a, b) => b.y - a.y).slice(0, 30);;
        
        setTotalIssuesBySkuData(data_array);
    }

    const generate = async() => {
        
        await getWarrantyData();

    }

    const handleDateChange = (date, field) => {
        let func = setStartDate;
        if (field === "endDate") func = setEndDate;
        if (date !== null){
            func({formatted: date.format("YYYY-MM-DD"), full: date});
        } else {
            func(date);

        }
    }

    const getWarrantyData = async () => {
        props.setLoading(true);
        const searchParams = {
            start_date: startDate !== null ? startDate.formatted : null,
            end_date: endDate !== null ? endDate.formatted : null,
            limit: limit ? limit : null
        }
        console.log('params', searchParams);
        try {
            const warranty_orders = await orderHelpers.getWarrantyOrderDetails(props.company, searchParams, props.token);
            setWarrantyOrders(warranty_orders);
            setTotalIssues(warranty_orders.length);
        } 
        catch (err) {
            console.log(err);
        }  
        finally {
            props.setLoading(false);
        }
    }

    return(
        <Box display = "flex" flexDirection = "column" className = {props.isMobile ? "mobile-top" : "desktop-top"}>
            <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{paddingLeft: 3}}>
                <h1>Warranty Graphs</h1> 
                <WarrantyGraphsQueryForm
                    generate = {generate}
                    handleDateChange = {handleDateChange}
                    startDate = {startDate?.full}
                    endDate = {endDate?.full}
                    warrantyOrders = {warrantyOrders}
                 />
               <WarrantyGraphsTable  
                    totalIssuesByDateData = {totalIssuesByDateData}
                    totalIssues = {totalIssues}
                    totalIssuesByReasonData = {totalIssuesByReasonData}
                    totalIssuesBySkuData = {totalIssuesBySkuData}
                    totalIssuesByVendorData = {totalIssuesByVendorData}
                    {...props} />
            
            </Box>
        </Box>
    )
}

export default WarrantyGraphs;