import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import WarrantyOrderOptions from './WarrantyOrderOptions';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';

function WarrantyOrderQueryForm(props) {

    const filter = createFilterOptions();

    return(
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: 'column'}}>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <label>Filter By</label>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{display: 'flex', flexDirection: props.isMobile ? 'column' : 'row', mb: '10px'}}>
            <FormControl sx = {{ mr: '0.6%', width: '20%' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label = "Start Date"
                        name = 'startDate'
                        onChange = {(event) => props.handleDateChange(event, 'startDate')}
                        value = {props.startDate ? props.startDate : null}
                        renderInput = {(params) => <TextField {...params} />}
                        clearable = {true}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx = {{ mr: '0.6%', width: '20%' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label = "End Date"
                        name = 'endDate'
                        onChange = {(event) => props.handleDateChange(event, 'endDate')}
                        value = {props.endDate ? props.endDate : null}
                        renderInput = {(params) => <TextField {...params} />}
                        clearable = {true}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl sx = {{ width: '20%', mr: '0.6%' }}>
                <AutoComplete
                    value = {WarrantyOrderOptions.order_status.find(option => option.value === props.orderStatus) || null}
                    onChange = {(event, newValue) => {
                        props.setOrderStatus(newValue ? newValue.value : null);
                    }}
                    id = "orderStatus"
                    options = {WarrantyOrderOptions.order_status}
                    getOptionLabel = {(option) => {
                        return `${option.name}`;
                    }}
                    renderOption = {(props, option) => {
                        return (
                            <li {...props}>
                                {`${option.name}`}
                            </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label="Order Status" />
                    )}
                />
            </FormControl>
            <Tooltip title = {"Clear filter."}  placement = 'right' >
                <span>
                    <IconButton 
                        aria-label = "clearFilter" 
                        onClick = {props.clearOrderSearch} 
                        sx = {{mt: '5px', color: '#96605a'}}>
                        <FilterAltOffIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
        <Box className = {props.isMobile ? "mobile-box" : "desktop-box"} sx = {{flexDirection: 'row', mb: '10px'}}>
            <TextField 
                    name = 'poNumber'
                    label = 'PO Number'
                    onChange = {props.handleChange}
                    value = {props.poNumber}
                    sx = {{width: '20%', mr: '0.6%'}}
            />
            <TextField 
                name = 'name'
                label = 'Customer Name'
                onChange = {props.handleChange}
                value = {props.name}
                sx = {{width: '20%', mr: '0.6%'}}
            />
            <FormControl sx = {{ width: '20%', mr: '0.6%'}}>
                <AutoComplete
                    value = {props.sku}
                    onChange = {(event, newValue) => {
                        if (typeof newValue === 'string') {
                            props.setSku({value: newValue,});
                        } else if (newValue && newValue.inputValue) {
                            props.setSku({value: newValue.inputValue,});
                        } else {
                            props.setSku(newValue ? newValue.value : null);
                        }
                    }}
                    filterOptions = {(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            value: `Select "${inputValue}"`
                        });
                        }
                        return filtered;
                    }}
                    isOptionEqualToValue = {(option, value) => option.value === value || value === ""}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id = "sku"  
                    options = {(props.skuList)} 
                    getOptionLabel = {(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.value;
                    }}
                    renderOption = {(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                        <li key={key} {...optionProps}>
                            {option.value}
                        </li>
                        );
                    }}
                    renderInput = {(params) => (
                        <TextField {...params} label="SKU" />
                    )}
                />
            </FormControl>
            <Tooltip title = {"Apply filter."}  placement = 'right' >
                <span>
                    <IconButton 
                    aria-label = "filter" 
                    onClick = {props.getWarrantyOrderDetails} 
                    sx = {{mt: '5px', color: '#7c8978'}}>
                        <FilterAltIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </Box> 
    </Box>
    )
}

export default WarrantyOrderQueryForm;