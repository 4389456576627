import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import bulkHelpers from '../../../helpers/bulk';
import './BulkProcessing.css';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import ErrorIcon from '@mui/icons-material/Error';

function ProcessingDetails(props) {

    const [override, setOverride] = useState("Kentucky");
    const [selected_rows, setSelectedRows] = useState([]);
    const [full_selected_rows, setFullSelectedRows] = useState([]);

    const columns = [ 
        {
            field: 'id',
            headerName: "ID",
            flex: 1,
            editable: false,
            minWidth: 100,
            hide: true
        },
        {
            field: 'internal_po_number',
            headerName: 'PO',
            flex: 1,
            editable: false,
            minWidth: 100
        }, 
        {
            field: 'internal_sku',
            headerName: 'Our SKU',
            flex: 1,
            editable: false,
            minWidth: 100
        }, 
        {
            field: 'internal_price',
            headerName: 'Our Price',
            flex: 1,
            editable: false,
            minWidth: 100
        },
        {
            field: 'external_price',
            headerName: 'Their Price',
            flex: 1,
            editable: false,
            minWidth: 100
        },
        {
            headerName: 'Ordered Qty',
            field: "quantity",
            sortable: false,
            editable: false,
            minWidth: 100,
            renderCell: (params) => {
                const quantity = Number(params.row.quantity);
                const case_pack = Number(params.row.case_packs);
                if (quantity % case_pack !== 0 || case_pack === 0) {
                    return (
                        <Tooltip title = {`Casepack: ${params.row.case_packs}`} placement = 'right' >
                            <span style = {{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                                {params.row.quantity} 
                                <ErrorIcon fontSize = "small" sx = {{ml: 1}}/>
                            </span>
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title = {`Casepack: ${params.row.case_packs}`} placement = 'right' >
                            <span style = {{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                                {params.row.quantity} 
                            </span>
                        </Tooltip>
                    );
                }
            }
        }, 
        {
            headerName: 'KY',
            field: "kentucky",
            sortable: false,
            editable: false,
            minWidth: 100,
        },
        {
            headerName: 'PA',
            field: "pennsylvania",
            sortable: false,
            editable: false,
            minWidth: 100,
        },
        {
            headerName: 'Decision',
            field: "decision",
            sortable: false,
            editable: false,
            minWidth: 200,
        }   
    
    ]

    const saveOverride = async() => {
        await props.overrideDecision(full_selected_rows, override);
        setOverride("Kentucky");
        setSelectedRows([]);
        setFullSelectedRows([]);
    }

    const pullFromAcumatica = async() => {
        props.toast.promise(pullFromAcumaticaHelper(), {
            success: "Updated Values From Acumatica",
            error: "Failed to pull order info from Acumtica"
        })
    }

    const pullFromAcumaticaHelper = async() => {
        try {
            let ordernbr = props.items.filter(obj=>obj.acu_order_num)[0];
            let req = await bulkHelpers.getAcumaticaOrder(ordernbr.acu_order_num, props.company, props.token)
            let order = req.SalesOrderBySODetails
            if(order.length > 0) {
                let items = {};
                for(let row of order) { 
                    items[row.InventoryID.value] = row.Quantity.value;
                }
                await overrideFromAcumatica(items);
            }
        }
        catch(err) {
            console.log(err);
            throw err;
        }

    }

    const overrideFromAcumatica = async(items) => {
        let cancel = [];
        let ky = [];
        let partial = [];
        for(let item of props.items) {
            if(!(item.internal_sku in items)) cancel.push(item);
            else if(item.quantity === items[item.internal_sku]) ky.push(item)
            else if(item.quantity > items[item.internal_sku]) partial.push(item)
        }
        if(cancel.length > 0) await props.overrideDecision(cancel, "Cancel")
        if(ky.length > 0)  await props.overrideDecision(ky, "Kentucky");
        if(partial.length > 0) await props.overrideDecision(partial, "Partial");
    }

    const getRowClassName = (params) => {
       const quantity = Number(params.row.quantity);
       const case_pack = Number(params.row.case_packs);
       if (quantity % case_pack !== 0 || case_pack === 0) {
        return "red-row";
       }
      }

    return(
        <Box className = {props.isMobile ? "mobile-table-box" : "desktop-table-box"} style = {{width:"100%"}}>
            {props.items && props.items.filter(obj=>obj.acu_order_num).length > 0 ?
            <Button onClick = {()=>pullFromAcumatica()}>Pull Decisions From Acumatica</Button>
            :null}
            {selected_rows.length > 0 ? 
            <Box display = "flex" style = {{marginBottom:"10px"}}>
                <FormControl>
                    <InputLabel id = "overrideLabel">Decision</InputLabel>
                    <Select style = {{width:"200px"}} labelId = "overrideLabel" value = {override} label = "Decision" onChange = {(e) => setOverride(e.target.value)}>
                        {props.decisions.map((obj, index) => {
                            return (
                                <MenuItem key={index} value={obj}>{obj}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Button onClick={() => saveOverride()}>Save</Button>
            </Box>


            : null}
            <DataGrid
                style = {{width:"100%"}}
                rows = {props.items}
                className = {props.isMobile ? "mobile-table" : "desktop-table"}
                columns = {columns}
                getRowId = {(row) => row.id}
                rowsPerPageOption = {[25, 50, 100]}
                checkboxSelection = {true}
                selectableRowsHeader = {true}
                selectionModel = {selected_rows}
                onSelectionModelChange = {(ids) => {
                    let filtered = props.items.filter((row) => {
                        return ids.includes(row.id) || ids.includes(row.id.toString());
                    })
                    setFullSelectedRows(filtered);
                    setSelectedRows(ids);
                }}
                getRowClassName = {getRowClassName}
                sortModel = {[{field:'id', sort: 'desc'}]}
            />
        </Box>   
    )


}

export default ProcessingDetails;